.header {
  .logo {
    &-container {
      display: inline-block;
      @include media-breakpoint-down(md) {
        display:none;
      }
    }

    &--link {
      display: block;
    }

    &--desktop {
      display: inline-block;
      height: 60px;

      @include media-breakpoint-down(md) {
        display:none;
      }
    }
  }
}