//
// Common: variables: colors
//
// @author: Rutger Laurman - lemone.com
//

//$white: #fff;
//
//$true-blue: #0081d2;
//$yale-blue: #0f4698;
//$charcoal: #313c4d;
//$yellow-green: #83cd32;
//$gray: #7e8288;
//$medium-turquoise: #42bfdd;
//$true-blue-alternative: #0f72df;
//$prussian-blue: #1a2f5a;
//$golden-poppy: #fec403;
//$hero-blue: #0e79f0;
//$baby-blue: #e7f5fc;
//
//$theme-colors: (
//  (
//    'success': $yellow-green,
//    'info': $medium-turquoise
//  )
//);

$azure-thursday-light-blue: #6EDCFC;
$azure-thursday-dark-blue: #2F77BD;
$azure-thursday-background-light: #06202d;
$azure-thursday-background-dark: #00141e;
$azure-thursday-red: #DF5959;
$azure-thursday-grey: #98A1A5;
$white: #FFF;