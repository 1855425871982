.subscribe {
  &-section {
    margin-top: 6em;
  }
   
  &-gradient {
    background: linear-gradient(180deg,  #00141E 0%, #082432 100%);
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: 50px;
    padding-top: 50px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }



  &-form-panel {
    color: $white;
    padding-bottom:2em;
    padding-left:3em;

    .mc-field-group {
      margin-bottom: 20px;

      label {
        font-weight: bold;
      }
    }

    .email {
      background-color: $azure-thursday-background-light;
      color: $white;
      width: 100%;
      padding: 10px;
      border-radius: 10px 10px 10px 0;
      border: 1px solid $azure-thursday-light-blue;
    }


    @include media-breakpoint-down(md) {
      padding-left:0;
    }

  }

  &-image-frame {

    @include media-breakpoint-down(md) {
      display: none;
    }
  }


}
