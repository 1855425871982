//
// Common: base
//
// @author: Brian Trommelen - lemone.com
//

@import 'bootstrap/scss/bootstrap-reboot';


html {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  @include media-breakpoint-down(md) {
    font-size: 15/16 + rem;
  }

  background-color: #f2f1e7;
}

a {
  color: #212529;
  text-decoration: underline;
}

section {
  //padding: 20px 0;
}

