.footer {
  position: relative;
  margin-bottom: 0;
  padding: 45px 0;
  text-align: center;

  &-logo {
    height: 30px;
  }

  &-copyright {
    padding-top: 30px;
    width: 100%;
  }

  &-socials {
    margin: 30px 0;
    padding: 0;
    text-align: center;
    list-style-type: none;
    display: inline-block;

    li {
      padding: 0 5px;
    }

    &--item {
      display: inline-block;

      img {
        height: 20px;
      }
    }
  }
}
