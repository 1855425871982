//
// Component: navbar
//
// @author: Rutger Laurman - lemone.com
//

.navbar {
  display: flex;
  justify-content: space-between;
  min-height: 100px;

  &-container {
    display: inline-flex;
    padding: 0;
    margin: 40px 0 15px 0;
  }

  &--item {
    display: inline-flex;

    &-link {
      display: inline-block;
      font-family: 'Poppins';
      font-size: 1em;
      font-weight: 400;
      //letter-spacing: 1.2px;
      padding-left: 1em;
      padding-right: 1em;      
      //padding-top: 1em;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
     }
    }

    &:first-child {
      padding-right: 0px;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      padding-left: 0em;
    }
  }
}

.logo-container {
 
  margin: 20px 0 10px 0;
}

.logo {
  display: none;
  
  &--link {
  }
  &--image {
    width: 150px;
  }


}