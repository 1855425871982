.text {
    &-section {
      
      font-family: Poppins;

      p {
 
      }

      h1 {

  
        @include media-breakpoint-up(md) {

        }
      }

      &-block {
        margin-bottom: 6rem;

        &-text {

          height: 100%;
          display: table;

          &-inner {
            display: table-cell;
            vertical-align: middle;
           
          }

          
        }

        &-img {
          width: 100%;
          border-radius: 12px;

          img {
            width: 100%;
            border-radius: 12px;
            box-shadow: 0px 0px 8px #dbd8c6;
          }

        }

        &-content {
          background-color:#fff;
          

          font-size: 1rem;
          font-weight: 400;
          padding: 2.5rem;
          border-radius: 12px;
          box-shadow: 0px 0px 8px #dbd8c6;
          
          
          h2 {
            font-weight: bold;
            padding-bottom: 1rem;
          }

          h3 {
            font-weight: bold;
            padding-bottom: 0.2rem;
            font-size: 1.4rem;
            padding-top: 1rem;
          }

          table {
            width: 100%;
            border-top: 1px solid #a8a68f;
          }

          table th,
          table td {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #a8a68f;
          }

        }

        &-img-left {
          margin-left: -25%;
        }

        &-content-left {
          margin-left: -50%;
        }

        &-img-right {
          margin-left: 25%;
          z-index: 100;
        }

        &-content-right {
          width: auto;
          margin-right: -50%;
          z-index: 200;
          position: relative;
        }

      }



    }


    &--banner {
      font-family: Poppins;
      background-color: #fff;
      border-radius: 12px;
      border: 0px solid #a8a68f;
      box-shadow: 0px 0px 8px #dbd8c6;
      margin-top: 2rem;
      margin-bottom: 6rem;

      &--text {
        padding-right: 2rem;
        padding-top: 2rem;
        padding-bottom: 2.5rem;
        padding-left: 1rem;


        &-right {
          padding-left: 3rem;
        }


        h2 {
          font-weight: bold;
          padding-bottom: 1rem;
        }
  
        h3 {
          font-weight: bold;
          padding-bottom: 0.2rem;
          font-size: 1.4rem;
          padding-top: 1rem;
        }

      }


      &--img {
        border-radius: 12px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-align: center;
        height: 100%;
        background-size: cover;
        background-position: center center;

        &-right {
          border-radius: 12px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

      }


    }


}