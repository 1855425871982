.hero {
  font-family: Poppins;
  padding-top: 2rem;
  padding-bottom: 4rem;

  &--heading {
    display: inline-block;
    margin: 20px 0 0;
    width: 100%;
    text-align: center;
    padding-bottom: 60px;
    
    h1 {
      margin: auto;
      font-size: 4em;
      font-weight: bold;
    }

    h2 {
      margin: auto;
      font-size: 2em;
      font-weight: 300;

      span {
        margin-left: 2rem;
        font-size: 1.2rem;
        font-weight: 400;
      }

    }
  }


  &--standingbanner {
    overflow: hidden;
    background-color: #fff;
    border: 0px solid #a8a68f;
    box-shadow: 0px 0px 8px #dbd8c6;
    border-radius: 8px;

    &--info {
      //background-color: #53a253;
      //color: #fff;
      padding: 2rem;
      padding-top: 1.9rem;      
      position: relative;
      z-index: 100;
      right:0px;

      h2 {
        font-weight: bold;
        font-size: 1.8rem;
        padding-bottom: 0.5rem;
      }
      
    }



  }


  &--button--full {
      width: 220px;
      margin:20px;
      
  }
}
