button {
  background-color: transparent;
}

.button {
  box-sizing: border-box;
  user-select: none;   

  &--rounded {
    border: 1px solid $azure-thursday-light-blue;
    border-radius: 10px 10px 10px 0;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.5);

    &:hover {
      background-color: rgba(180,180,180,0.2);
    }
  }

  &--rounded--light {
    background-color: #FFF;
    border: 1px solid #FFF;
    border-radius: 10px 10px 10px 0;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.5);

    &:hover {
      background-color: rgba(110,220,252,0.8);
    }
  }

  &--subscribe {
    padding: 17px 14px 14px 14px;
    border: 1px solid $azure-thursday-light-blue;
    border-radius: 10px 10px 10px 0;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.5);

    &:hover {
      background-color: rgba(180,180,180,0.2);
    }
  }

  &:hover {
    background-color: rgba(180,180,180,0.2);
  }
}

.button-text {
  font-family: Poppins;
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 1.2px;
  top: -2px;
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
  text-align: center;
  user-select: none;   

  .button--rounded & {
    color: #FFF;

    &:hover {
      color: white;
    }
  }

  .button--rounded--light & {
    color: #000;

    &:hover {
      color: black;
    }
  }

  .button--subscribe & {
    color: #FFF;

    &:hover {
      color: white;
    }
  }

  &:hover {
    text-decoration: none;
    color: white;
  }
}

.plus-sign {
  font-weight: 300;
  display: inline-block;
  position: relative;
  top: 4px;
  font-size: 1.8em;
  margin-right: 3px;
}