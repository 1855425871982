$custom-select-border-radius: 8px 8px 8px 0px;
$custom-select-height: 40px;
$custom-select-bg: $azure-thursday-background-light; //$azure-thursday-dark-blue;
$custom-select-color: $white;
$custom-select-border-color: $custom-select-bg;
$custom-select-bg-size: 10px 6px; // In pixels because image dimensions

$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'><polyline fill='none' stroke='#FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' points='1 1 5 5 9 1'/></svg>");
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center /
  $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)
